import { useState } from 'react';
import { useLayoutEffect } from './useLayoutEffect';

class ScrollbarGutterManager {
  private static instance: ScrollbarGutterManager | null = null;

  private subscribers = new Set<(size: number) => void>();
  private observer: ResizeObserver | null = null;
  private measurementDiv: HTMLDivElement | null = null;

  private constructor() {}

  static getInstance() {
    if (!ScrollbarGutterManager.instance) {
      ScrollbarGutterManager.instance = new ScrollbarGutterManager();
    }
    return ScrollbarGutterManager.instance;
  }

  private ensureInit() {
    if (this.measurementDiv) return;

    this.measurementDiv = document.createElement('div');
    this.measurementDiv.style.width = '100px';
    this.measurementDiv.style.height = '100px';
    this.measurementDiv.style.overflowY = 'scroll';
    this.measurementDiv.style.position = 'absolute';
    this.measurementDiv.style.top = '-9999px';
    this.measurementDiv.style.visibility = 'hidden';
    document.body.appendChild(this.measurementDiv);

    if (typeof ResizeObserver === 'function') {
      this.observer = new ResizeObserver(() => {
        this.notifySubscribers();
      });
      this.observer.observe(document.documentElement);
    }

    // Initial notify
    this.notifySubscribers();
  }

  private measure(): number {
    if (!this.measurementDiv) return 0;
    return this.measurementDiv.offsetWidth - this.measurementDiv.clientWidth;
  }

  private notifySubscribers() {
    const size = this.measure();
    for (const cb of this.subscribers) {
      cb(size);
    }
  }

  subscribe(callback: (size: number) => void) {
    if (typeof window === 'undefined') return; // Skip on server
    this.ensureInit();
    this.subscribers.add(callback);
    // Immediately call with current value
    callback(this.measure());
  }

  unsubscribe(callback: (size: number) => void) {
    this.subscribers.delete(callback);
    if (this.subscribers.size === 0) {
      setTimeout(() => {
        this.cleanup();
      }, 5000); // Delay cleanup by 5 seconds
    }
  }

  private cleanup() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
    if (this.measurementDiv) {
      document.body.removeChild(this.measurementDiv);
      this.measurementDiv = null;
    }
    ScrollbarGutterManager.instance = null;
  }
}

export default function useScrollbarGutterSize(): number {
  const [gutterSize, setGutterSize] = useState(0);

  useLayoutEffect(() => {
    const manager = ScrollbarGutterManager.getInstance();
    manager.subscribe(setGutterSize);
    return () => {
      manager.unsubscribe(setGutterSize);
    };
  }, []);

  return gutterSize;
}
